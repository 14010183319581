import styles from './User.module.scss'
import { Link } from "react-router-dom"
import { FC } from "react"
import { IUser } from "../../app/store/users/user.type"
import { Avatar } from "../Avatar"
import Cookies from "js-cookie"
import { ucFirst } from '../../functions/global'

export const User: FC<{ user: IUser }> = (props) => {
    const user = props.user

    const scrollState = () => {
        Cookies.set("scrollState", window.scrollY.toString(), {
            expires: new Date(new Date().getTime() + 5 * 60 * 1000), // 5 min
        })
    }

    return (
        <div className={styles.card}>
            <Link
              to={`/${user.id}`}
              state={{ user }}
              onClick={() => scrollState()}
            >
                <div className={styles.content}>
                    <div className={styles.info}>
                        <Avatar images={[user.avatarUrl]} />
                        <div className={styles.name}>{user.fullName}</div>
                        <div className={styles.item}>{ucFirst(user.city)}</div>
                        {user.expertise?.map((item) => <div className={styles.item}>{ucFirst(item)}</div>)}
                    </div>
                    <div className={styles.button}>К анкете</div>
                </div>
            </Link>
        </div>
    )
}
