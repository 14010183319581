import React, {useEffect, useRef} from 'react';
import styles from './PinInput.module.scss';

interface PinInputProps {
	value: string;
	onChange: (value: string) => void;
}

export const PinInput: React.FC<PinInputProps> = ({ value, onChange }) => {

	const inputRef = useRef<HTMLInputElement>(null);

	useEffect(() => {
		if (inputRef.current) {
			inputRef.current.focus();
		}
	}, []);

	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const newValue = e.target.value.replace(/\D/g, '');
		onChange(newValue.slice(0, 6));
	};

	const handlePaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
		e.preventDefault();
		const pasteData = e.clipboardData.getData('text').replace(/\D/g, '');
		onChange(pasteData.slice(0, 6));
	};

	const filled = value.split('').join('');
	const empty = '——————';

	return (
		<div className={styles.pinInputContainer}>
			<input
				ref={inputRef}
				type="text"
				inputMode="numeric"
				pattern="[0-9]*"
				value={value}
				onChange={handleChange}
				onPaste={handlePaste}
				maxLength={6}
				className={styles.pinInput}
				style={{paddingRight: `calc(49px * ${empty.length - filled.length})`}}
			/>
			<div className={styles.placeholder}>{filled + empty.slice(filled.length)}</div>
		</div>
	);
};
