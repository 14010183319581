import React, { createContext, useContext, useRef } from 'react';

interface InputFocusContextType {
	inputRef: React.RefObject<HTMLInputElement>;
}

const InputFocusContext = createContext<InputFocusContextType | undefined>(undefined);

export const InputFocusProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
	const inputRef = useRef<HTMLInputElement>(null);

	return (
		<InputFocusContext.Provider value={{ inputRef }}>
			{children}
		</InputFocusContext.Provider>
	);
};

export const useInputFocus = (): InputFocusContextType => {
	const context = useContext(InputFocusContext);
	if (!context) {
		throw new Error('useInputFocus must be used within a InputFocusProvider');
	}
	return context;
};
