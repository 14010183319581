export const TG = window.Telegram.WebApp
const envUser = JSON.parse(process.env.REACT_APP_TELEGRAM_USER || '{}')
export const TGUser = Object.keys(envUser).length ? envUser : TG.initDataUnsafe.user
export const apiDomain = () => {
  if (window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1') {
    return `${(process.env.REACT_APP_BACKEND_URL || "")}`
  }else{
    return '/api'
  }
}

export const isJsonString = (str:string) => {
  try {
    JSON.parse(str)
  } catch (e) {
    return false
  }
  return true
}

export const isValidUrl = (str:string) => {
  try {
    new URL(str)
    return true;
  } catch (err) {
    return false
  }
}

export const ucFirst = (str:string) => {
  if (!str) return str
  return str[0].toUpperCase() + str.slice(1)
}

export const calculateAge = (birthDateString: string): number => {
  const birthDate: Date = new Date(birthDateString);
  const currentDate: Date = new Date();

  let age: number = currentDate.getFullYear() - birthDate.getFullYear();
  const monthDiff: number = currentDate.getMonth() - birthDate.getMonth();
  const dayDiff: number = currentDate.getDate() - birthDate.getDate();

  if (monthDiff < 0 || (monthDiff === 0 && dayDiff < 0)) {
    age--;
  }

  return age;
}

export const declension = (number: number, titles: [string, string, string]): string => {
  const cases = [2, 0, 1, 1, 1, 2];
  const titleIndex =
    (number % 100 > 4 && number % 100 < 20) ? 2 : cases[Math.min(number % 10, 5)];
  return titles[titleIndex];
}