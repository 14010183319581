import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAuth } from '../hooks/useAuth'; // Путь к вашему хуку
import { ThreeDots } from 'react-loader-spinner'; // Путь к вашему хук

interface PrivateRouteProps {
	element: React.ReactElement;
}

export const PrivateRoute: React.FC<PrivateRouteProps> = ({ element }) => {
	const { isAuthenticated, loading } = useAuth();
	const location = useLocation();

	if (loading) {
		return (
			<ThreeDots
				height="50"
				width="50"
				radius="9"
				color="#1864e7"
				ariaLabel="three-dots-loading"
				wrapperClass="loader"
				visible={true}
			/>
		);
	}

	return isAuthenticated ? element : <Navigate to="/login" state={{ from: location }} />;
};
