import styles from './NotFound.module.scss'
import {ReactComponent as NotFoundIcon} from '../../assets/icons/not_found.svg'

export const NotFound = () => {
  return(
    <div className={styles.notFound}>
      <NotFoundIcon/>
      <div className={styles.text}>По вашему запросу не удалось ничего найти</div>
    </div>
  )
}