import React from "react"
import styles from "./Modal.module.scss"
import {ReactComponent as CloseIcon} from '../../assets/icons/close.svg'

export const Modal = ({ isVisible = false, title, content, footer, onClose }: any) => {
  const keydownHandler = ({ key }: any) => {
    switch (key) {
      case 'Escape':
        onClose()
        break;
      default:
    }
  };

  React.useEffect(() => {
    document.addEventListener('keydown', keydownHandler);
    return () => document.removeEventListener('keydown', keydownHandler);
  });

  return !isVisible ? null : (
    <div className={styles.modal} onClick={onClose}>
      <div className={styles.dialog} onClick={e => e.stopPropagation()}>
        <div className={styles.header}>
          <h3 className={styles.title}>{title}</h3>
          {
            onClose
            ? <span className={styles.close} onClick={onClose}>
                <CloseIcon/>
              </span>
            : ''
          }
        </div>
        <div className={styles.body}>
          <div className={styles.content}>{content}</div>
        </div>
        {footer && <div className={styles.footer}>{footer}</div>}
      </div>
    </div>
  )
}